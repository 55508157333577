.recurrence_form .recurrence_section
{
    border-bottom: 1px solid var(--light-gray);
}

.recurrence_form .crack-ux-radio.on_type .radio-container
{
    display: inline-block !important;
    margin-right: 15px;
}

.recurrence_form .day_selector_container .crack-ux-check-box
{
    margin-right: 10px;
}

.recurrence_form .day_selector_container .crack-ux-check-box .check-box-label
{
    display: inline-block;
    width: 75px;
}