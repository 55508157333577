.business-layout > .navigation
{
	display: inline-block;
	width: auto;
	position: absolute;
	pointer-events: none;
	width: 100%;
	top: 0;
	left: 0;
}

@media screen and (min-width: 768px)
{
	.business-layout > .navigation
	{
		width: auto;
		position: static;
	}
}

.business-layout > .navigation > .navigation-modules,
.business-layout > .navigation > .navigation-menu
{
	pointer-events: all;
}

.business-layout > .content
{
	width: 100%;
	display: inline-block;
	transition: width 0.3s ease-in-out;
}

.business-layout > .content.expanded
{
	width: calc(100% - 42px);
}

.business-layout > .content.contracted
{
	width: calc(100% - 282px);
}

.business-layout > .content > .crack-ux-header
{
	height: var(--header);	
}

.business-layout > .content > .crack-ux-header .work-route
{
	margin-top: 2px;
	margin-left: 36px;
}

@media screen and (min-width: 768px)
{
	.business-layout > .content > .crack-ux-header .work-route
	{
		margin-top: 1px;
		margin-left: 36px;
	}
}

@media screen and (min-width: 1024px)
{
	.business-layout > .content > .crack-ux-header .work-route
	{
		margin-top: 0px;
		margin-left: 0px;
	}
}

.business-layout > .content > .crack-ux-header .work-route .business-dropdown-box
{
	margin-top: -11px;
}

@media screen and (min-width: 1024px)
{
	.business-layout > .content > .crack-ux-header .work-route .business-dropdown-box
	{
		margin-left: 0px;
	}
}

.business-layout > .content > .crack-ux-header .work-route .breadcrumbs .crack-ux-text
{
	max-width: 120px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media screen and (min-width: 768px)
{
	.business-layout > .content > .crack-ux-header .work-route .breadcrumbs .crack-ux-text
	{
		max-width: none;
		text-overflow: unset;
	}
}

.business-layout > .content > .crack-ux-header .messages
{
	margin-right: 15px;
}

.business-layout > .content > .crack-ux-header .notifications
{
	margin-right: 15px;
}

.business-layout > .content > .crack-ux-header .profile-account
{
	height: var(--header);
	margin-top: -16px;
	padding: 16px 0px;
}

.business-layout > .content > .crack-ux-body
{
	height: calc(100vh - var(--header));
}

/*************************************
*		Multi Function Panel		 *
*************************************/

.business-layout > .multi-function-panel
{
	width: 100%;
	height: calc(100vh - var(--header));
	top: var(--header);
	left: auto;
	right: 0px;
	box-shadow: -2px 0px 5px rgb(0, 0, 0, 0.1);
}

@media screen and (min-width: 768px)
{
	.business-layout > .multi-function-panel
	{
		width: 420px;
	}
}

/* Account */
.business-layout > .multi-function-panel .account
{
	background-color: #f6f6f6;
	height: 100%;
}

.business-layout > .multi-function-panel .account .crack-ux-header
{
	padding-bottom: 0px;
}

.business-layout > .multi-function-panel .account .crack-ux-header .crack-ux-card
{
	padding: 15px;
	border-radius: 5px;
	background-color: var(--blue);
	color: #efefef;
}

.business-layout > .multi-function-panel .account .crack-ux-header .crack-ux-card .info
{
	margin-left: 15px;
}

.business-layout > .multi-function-panel .account .crack-ux-header .crack-ux-card .info .fullname
{
	font-size: 1.5em;
}

.business-layout > .multi-function-panel .account .crack-ux-header .crack-ux-card .info .username
{
	font-weight: bold;
}

.business-layout > .multi-function-panel .account .crack-ux-body .menu-option
{
	width: 100%;
	margin-bottom: 15px;
	padding: 15px;
	border-radius: 5px;
	cursor: pointer;
}

.business-layout > .multi-function-panel .account .crack-ux-body .menu-option:hover
{
	background-color: #efefef;
}

.business-layout > .multi-function-panel .account .crack-ux-body .menu-option .crack-ux-text,
.business-layout > .multi-function-panel .account .crack-ux-body .menu-option .crack-ux-icon
{
	cursor: pointer;
}

.business-layout > .multi-function-panel .account .crack-ux-body .menu-option .crack-ux-text
{
	margin-left: 15px;
}

/*****************************************
*		General Styles For Layout		 *
*****************************************/
.title
{
	font-weight: bold;
}

.crack-ux-content > .crack-ux-panel
{
	height: 100%;
}

.crack-ux-modal > .crack-ux-box
{
	width: 100%;
	height: 100%;
}

@media screen and (min-width: 768px)
{
	.crack-ux-modal > .crack-ux-box
	{
		width: auto;
		height: auto;
	}
}

.crack-ux-modal > .crack-ux-box > .crack-ux-card
{
	width: 100%;
	height: 100%;
}

@media screen and (min-width: 768px)
{
	.crack-ux-modal > .crack-ux-box > .crack-ux-card:not(.location)
	{
		width: 480px;
		height: auto;
	}

	.crack-ux-confirm > .crack-ux-modal > .crack-ux-box > .crack-ux-card
	{
		width: auto;
		height: auto;
	}
}

@media screen and (min-width: 1024px)
{
	.crack-ux-modal > .crack-ux-box > .crack-ux-card.location
	{
		width: 1024px;
		height: auto;
	}
}

.crack-ux-modal > .crack-ux-box > .crack-ux-card > .crack-ux-header:first-child
{
	background-color: var(--blue);
	color: var(--white);
	border: none;
}

.crack-ux-modal > .crack-ux-box > .crack-ux-card > .crack-ux-body:has(.crack-ux-form)
{
	height: calc(100% - var(--header) - var(--footer) + 1px);
	overflow-y: auto;
}

@media screen and (min-width: 768px)
{
	.crack-ux-modal > .crack-ux-box > .crack-ux-card > .crack-ux-body:has(.crack-ux-form)
	{
		height: auto;
		max-height: calc(100vh - var(--header) - var(--footer) + 1px);
	}
}

/*****************************************
*		General Styles For Reports		 *
*****************************************/
.report-container > .report .crack-ux-card
{
	height: auto;
	margin-bottom: 10px;
}

.report-container > .report .crack-ux-card
{
	height: auto;
	margin-bottom: 10px;
}

.report-container > .report .header
{
	padding: 15px;
}