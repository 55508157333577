.product-form .pictures 
{
	background: var(--backgroundInput);
	border: solid 1px var(--borderInput);
}

.product-form .pictures > * 
{
	margin-right: 15px;
}

.product-form .pictures > *:last-child
{
	margin-right: 0px;
}

.product-form .pictures .add-picture
{
	border: dashed 1px var(--light-gray);
	width: 100px;
	height: 100px;
}

.product-form .features 
{
	background: var(--backgroundInput);
	border: solid 1px var(--borderInput);
}

.product-form .features .crack-ux-input .input
{
	background: var(--white);
}