.business-layout .business-selector
{
	min-width: auto;
}

.business-layout .business-selector .select
{
	background: transparent;
	color: #ffffff;
	margin: 0px;
	border: none;
	padding: 10px 2px 10px 10px;
}

.business-layout .business-selector .option
{
	background: #ffffff;
}

.business-layout .business-selector .option:hover
{
	background: #fafafa;
}

.business-layout .business-selector .crack-ux-box.options
{
	width: 150px;
}