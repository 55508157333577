/* General */
.public-layout
{
	overflow: auto;
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.public-layout::-webkit-scrollbar
{
	display: none; 
}

.public-layout .sice-home .title
{
	display: block;
	color: #FFFFFF;
	text-align: center;
	font-size: 2.5rem;
}

.public-layout .sice-home .subtitle
{
	display: block;
	color: #FFFFFF;
	text-align: center;
	font-size: 1.5rem;
	margin-top: 1rem;
	margin-bottom: 2rem;
}

.public-layout .sice-home .tag
{
	font-size: 0.875rem;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	margin-bottom: 1.5rem;
}

.public-layout .sice-home .tag.tag-green
{
	background-color: #D1FAE5;
	color: #065F46;
}

.public-layout .sice-home .tag.tag-yellow
{
	background-color: #FEF9C3;
	color: #92400E;
}

.public-layout .sice-home .tag.tag-blue
{
	background-color: #E0E7FF;
	color: #1E40AF;
}

/* Menu */
.public-layout .sice-home .menu
{
	width: 100%;
	border: none;
	position: absolute;
	text-align: center;
	z-index: 10;
}

@media screen and (min-width: 1280px)
{
	.public-layout .sice-home .menu
	{
		width: var(--desktop);
		left: calc(50% - var(--desktop) / 2);
	}
}

.public-layout .sice-home .menu .logo
{
	font-size: 3rem;
}

.public-layout .sice-home .menu .login-button
{
	border: 1px solid rgba(255, 255, 255, 0.3);
	background: rgba(255, 255, 255, 0.25);
	color: #FFFFFF;
	margin-top: 0.4rem;
	font-size: 1.25rem;
	padding: 0.75rem 1.5rem;
	border-radius: 0.75rem;
	cursor: pointer;
}

/* Hero */
.public-layout .sice-home .hero
{
	background: url('/public/sice-page/background.png') no-repeat;
	background-size: cover;
	background-position: 68% center;
}

@media screen and (min-width: 768px)
{
	.public-layout .sice-home .hero
	{
		background-position: center 40%;
	}
}

.public-layout .sice-home .hero .content
{
	position: absolute;
	left: 20px;
	bottom: 30px;
}

@media screen and (min-width: 768px)
{
	.public-layout .sice-home .hero .content
	{
		width: 400px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin-top: 350px;
		margin-left: -150px;
	}
}

@media screen and (min-width: 1024px)
{
	.public-layout .sice-home .hero .content
	{
		width: 450px;
		margin-top: 60px;
		margin-left: -300px;
	}
}

@media screen and (min-width: 1366px)
{
	.public-layout .sice-home .hero .content
	{
		margin-top: 125px;
	}
}

.public-layout .sice-home .hero .content .main-phrase,
.public-layout .sice-home .hero .content .subtext
{
	color: #FFFFFF;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.public-layout .sice-home .hero .content .main-phrase
{
	font-size: 3rem;
	font-weight: bold;
}

.public-layout .sice-home .hero .content .subtext
{
	font-size: 1.5rem;
	margin-top: 1rem;
}

.public-layout .sice-home .hero .content .actions
{
	margin-top: 2rem;
}

.public-layout .sice-home .hero .content .actions .crack-ux-button
{
	padding: 0.75rem 1.5rem;
	border-radius: 0.75rem;
	font-size: 1.25rem;
	color: #efefef;
}

.public-layout .sice-home .hero .content .actions .cta1
{
	background-color: var(--orange);
}

.public-layout .sice-home .hero .content .actions .cta1:hover
{
	background-color: #e07808;
}

.public-layout .sice-home .hero .content .actions .cta2
{
	background-color: rgba(0, 0, 0, 0.35)
}

.public-layout .sice-home .hero .content .actions .cta2:hover
{
	background-color: rgba(0, 0, 0, 1);
}

/* What is SICE */
.public-layout .sice-home .what-is-sice
{
	background-color: #0d2743;
	padding: 4rem 1rem;
}

@media screen and (min-width: 768px)
{
	.public-layout .sice-home .what-is-sice .benefits-grid
	{
		width: 600px;
	}
}

.public-layout .sice-home .what-is-sice .benefit
{
	background-color: #FFFFFF;
	border-radius: 1rem;
	padding: 2rem;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.public-layout .sice-home .what-is-sice .icon
{
	width: 64px;
	height: 64px;
	margin-bottom: 1rem;
}

.public-layout .sice-home .what-is-sice .benefit-title
{
	margin-left: 15px;
	width: calc(100% - 79px);
	font-size: 1.5rem;
	font-weight: bold;
	color: #111827;
	margin-bottom: 1rem;
}

.public-layout .sice-home .what-is-sice .benefit-description
{
	font-size: 1.25rem;
	color: #4B5563;
	line-height: 1.6em;
}

/* Why Us */
.public-layout .sice-home .why-us
{
	background-color: #0d2743;
	padding: 4rem 1rem;
}

.public-layout .sice-home .why-us .content
{
	width: 100%;
}

@media screen and (min-width: 1024px)
{
	.public-layout .sice-home .why-us .content
	{
		width: 850px;
	}
}

.public-layout .sice-home .why-us .content .crack-ux-panel
{
	padding: 10px 20px;
}

.public-layout .sice-home .why-us .content .crack-ux-card
{
	margin-top: 20px;
	border-radius: 0.75rem;
}

.public-layout .sice-home .why-us .content .crack-ux-header
{
	border: none;
	padding-top: 25px;
	padding-bottom: 0px;
}

.public-layout .sice-home .why-us .content .crack-ux-header > .crack-ux-text
{
	text-align: center;
	font-size: 2.5rem;
}

.public-layout .sice-home .why-us .content .crack-ux-body
{
	height: 250px;
	padding: 0px;
}

.public-layout .sice-home .why-us .content .crack-ux-footer
{
	border: none;
	padding-top: 0px;
	height: 175px;
}

.public-layout .sice-home .why-us .content .crack-ux-footer > .crack-ux-text
{
	display: block;
	text-align: center;
	font-size: 1.5rem;
	line-height: 1.5em;
}

.public-layout .sice-home .why-us .content .cta
{
	background-color: var(--blue);
	color: #FFFFFF;
	font-size: 1.5rem;
	margin-top: 2rem;
	padding: 0.75rem 1.5rem;
	border-radius: 0.75rem;
}

/* Modules */
.public-layout .sice-home .modules
{
	background-color: #F9FAFB;
	padding: 4rem 1rem;
}

.public-layout .sice-home .modules .title
{
	color: #111827;
}

.public-layout .sice-home .modules .subtitle
{
	color: #4B5563;
	margin-bottom: 2rem;
}

.public-layout .sice-home .modules .module
{
	background-color: #FFFFFF;
	border-radius: 1rem;
	padding: 2rem 1.5rem;
	text-align: center;
}

.public-layout .sice-home .modules .image
{
	height: 150px;
	margin-bottom: 1rem;
}

.public-layout .sice-home .modules .module-title
{
	font-size: 1.5rem;
	font-weight: bold;
	color: #111827;
	margin-bottom: 0.75rem;
	display: block;
}

.public-layout .sice-home .modules .module-description
{
	font-size: 1rem;
	color: #4B5563;
	line-height: 1.5em;
	margin-bottom: 1.5rem;
	display: block;
}

.public-layout .sice-home .modules .module-button
{
	font-size: 1rem;
	padding: 0.5rem 1.25rem;
	border-radius: 0.5rem;
}

/* Commerce */
.public-layout .sice-home .commerce
{
	background-color: #00214D;
	color: #FFFFFF;
	padding: 4rem 1rem;
}

@media screen and (min-width: 1024px)
{
	.public-layout .sice-home .commerce
	{
		height: 700px;
	}
}

.public-layout .sice-home .commerce .title
{
	font-size: 3rem;
	text-align: left;
	font-weight: bold;
	margin-bottom: 1rem;
}

.public-layout .sice-home .commerce .subtitle
{
	text-align: left;
	font-size: 3rem;
	margin-bottom: 2rem;
	color: #D1D5DB;
}

.public-layout .sice-home .commerce .description
{
	font-size: 1.25rem;
	color: #D1D5DB;
	margin-bottom: 2rem;
	line-height: 1.6em;
}

.public-layout .sice-home .commerce .features
{
	list-style: none;
	padding: 0;
	margin: 0 0 2rem 0;
}

.public-layout .sice-home .commerce .features li
{
	font-size: 1.5rem;
	position: relative;
	padding-left: 1.5rem;
	margin-left: 15px;
	margin-bottom: 1rem;
}

.public-layout .sice-home .commerce .features li::before
{
	content: '✔';
	color: var(--orange);
	position: absolute;
	left: -10px;
	font-weight: bold;
}

.public-layout .sice-home .commerce .available-button
{
	background-color: var(--orange);
	color: #FFFFFF;
	font-size: 1.25rem;
	padding: 0.75rem 1.5rem;
	border-radius: 0.75rem;
	cursor: pointer;
}

.public-layout .sice-home .commerce .mockup
{
	height: 25rem;
	margin-top: 2rem;
}

@media screen and (min-width: 768px)
{
	.public-layout .sice-home .commerce .mockup
	{
		height: 50rem;
	}
}

@media screen and (min-width: 1024px)
{
	.public-layout .sice-home .commerce .mockup
	{
		margin-top: 0;
		height: 100%;
	}
}

.public-layout .sice-home .commerce .mockup img
{
	width: 100%;
	max-width: 600px;
	border-radius: 1rem;
}

/* Coming */
.public-layout .sice-home .coming
{
	background-color: #fbfbfb;
	padding: 4rem 1rem;
}

.public-layout .sice-home .coming .crack-ux-content
{
	width: 100%;
	margin: 0px auto;
}

@media screen and (min-width: 768px)
{
	.public-layout .sice-home .coming .crack-ux-content
	{
		width: 1024px;
	}
}

.public-layout .sice-home .coming .content
{
	border-radius: 1rem;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.public-layout .sice-home .coming .title
{
	font-weight: bold;
	color: #111827;
}

.public-layout .sice-home .coming .subtitle
{
	color: #111827;
	margin-bottom: 2rem;
}

.public-layout .sice-home .coming .crack-ux-panel
{
	padding: 0;
}

.public-layout .sice-home .coming .crm,
.public-layout .sice-home .coming .accountancy
{
	flex: 1;
	padding: 25px 35px;
	box-shadow: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 550px;
}

.public-layout .sice-home .coming .crm
{
	border-radius: 1rem 1rem 0px 0px;
}

@media screen and (min-width: 768px)
{
	.public-layout .sice-home .coming .crm
	{
		border-radius: 1rem 0px 0px 1rem;
	}
}

.public-layout .sice-home .coming .accountancy
{
	border-radius: 0px 0px 1rem 1rem;
	background-color: #fcfcfe;
}

@media screen and (min-width: 768px)
{
	.public-layout .sice-home .coming .accountancy
	{
		border-radius: 0px 1rem 1rem 0px;
	}
}

.public-layout .sice-home .coming .module
{
	display: block;
	margin-bottom: 1rem;
	font-weight: bold;
	font-size: 2.5rem;
}

.public-layout .sice-home .coming .caption
{
	display: block;
	font-size: 2.5rem;
	margin-bottom: 1.5rem;
}

.public-layout .sice-home .coming .description
{
	display: block;
	font-size: 1.25rem;
	color: #6B7280;
	margin-top: 3rem;
	margin-bottom: 3rem;
	line-height: 1.6em;
}

.public-layout .sice-home .coming .features
{
	list-style: none;
	padding: 0;
	margin: 0 0 1.5rem 0;
}

.public-layout .sice-home .coming .features .feature
{
	display: flex;
	align-items: center;
	font-size: 1.5rem;
	color: #374151;
	margin-bottom: 1rem;
}

.public-layout .sice-home .coming .features .feature .crack-ux-icon
{
	background-color: #E0E7FF;
	color: #1E40AF;
	font-size: 1.5rem;
	margin-right: 1rem;
	padding: 0.5rem;
	border-radius: 0.5rem;
}

.public-layout .sice-home .coming .actions
{
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 1rem;
}

.public-layout .sice-home .coming .dates
{
	font-size: 1.25rem;
	color: #6B7280;
}

.public-layout .sice-home .coming .beta-button
{
	background-color: #2563EB;
	color: #FFFFFF;
	font-size: 1rem;
	padding: 0.75rem 1.5rem;
	border-radius: 0.75rem;
}

.public-layout .sice-home .coming .reminder-button
{
	background-color: #FFFFFF;
	color: #111827;
	border: 1px solid #D1D5DB;
	font-size: 1rem;
	padding: 0.75rem 1.5rem;
	border-radius: 0.75rem;
}

/* Testimonials */
.public-layout .sice-home .testimonials
{
	background: linear-gradient(180deg, #003A73 0%, #004A91 100%);
	padding: 4rem 1rem;
}

.public-layout .sice-home .testimonials .title
{
	text-align: center;
	font-size: 2.5rem;
	font-weight: bold;
	margin-bottom: 1.5rem;
}

.public-layout .sice-home .testimonials .subtitle
{
	text-align: center;
	font-size: 1.5rem;
	color: #D1D5DB;
	margin-bottom: 4rem;
}

.public-layout .sice-home .testimonials .testimonial
{
	background-color: #FFFFFF;
	color: #111827;
	border-radius: 1.5rem;
	padding: 2rem;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.public-layout .sice-home .testimonials .testimonial .quote
{
	font-size: 1.35rem;
	font-style: italic;
	color: #374151;
	margin-bottom: 1.25rem;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 5em;
}

.public-layout .sice-home .testimonials .testimonial .name
{
	font-weight: bold;
	font-size: 1.2rem;
	color: #111827;
	display: block;
	margin-bottom: 0.25rem;
}

.public-layout .sice-home .testimonials .testimonial .role
{
	font-size: 1.1rem;
	color: #6B7280;
	display: block;
}

/* Contact Form */
.public-layout .sice-home .contact-us
{
	background: url('/public/sice-page/background-contact-us.png') no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-position: 60% center;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5rem 1rem;
}

@media screen and (min-width: 1440px)
{
	.public-layout .sice-home .contact-us
	{
		background-position: center 50px;
	}
}

.public-layout .sice-home .contact-us .form-side
{
	flex: 1;
	width: 100%;
}

@media screen and (min-width: 768px)
{
	.public-layout .sice-home .contact-us .form-side
	{
		flex: 1;
		width: 450px;
	}
}

@media screen and (min-width: 1024px)
{
	.public-layout .sice-home .contact-us .form-side
	{
		flex: 1;
		width: 600px;
	}
}

.public-layout .sice-home .contact-us .form-side > .crack-ux-header
{
	border: none;
}

.public-layout .sice-home .contact-us .title
{
	font-size: 2.5rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

@media screen and (min-width: 768px)
{
	.public-layout .sice-home .contact-us .title
	{
		font-size: 3rem;
	}
}

.public-layout .sice-home .contact-us .subtitle
{
	font-size: 1.35rem;
	color: #D1D5DB;
	margin-bottom: 2rem;
	line-height: 1.5em;
}

@media screen and (min-width: 768px)
{
	.public-layout .sice-home .contact-us .subtitle
	{
		font-size: 1.5rem;
	}
}

.public-layout .sice-home .contact-us .crack-ux-body
{
	background-color: rgba(0, 0, 0, 0.25);
	padding: 2rem;
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.public-layout .sice-home .contact-us .crack-ux-form .crack-ux-input .input,
.public-layout .sice-home .contact-us .crack-ux-form .crack-ux-select
{
	background-color: transparent;
	border: none;
	border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}

.public-layout .sice-home .contact-us .crack-ux-form .crack-ux-input > .crack-ux-text
{
	display: none;
}

.public-layout .sice-home .contact-us .crack-ux-form .crack-ux-input .input input
{
	font-size: 1.5rem;
	color: #efefef;
}

.public-layout .sice-home .contact-us .crack-ux-form .crack-ux-input .input input::placeholder
{
	color: #bbbbbb;
}

.public-layout .sice-home .contact-us .cta
{
	background-color: #FF3131;
	color: #FFFFFF;
	padding: 1rem 1.5rem;
	border-radius: 0.75rem;
	font-size: 1.5rem;
}

.public-layout .sice-home .contact-us .footer-note
{
	text-align: center;
	font-size: 1.25rem;
	color: #E5E7EB;
	margin-top: 0.5rem;
}

/* End */
.public-layout .sice-home .end
{
	background-color: #0C1A2B;
	color: #E5E7EB;
	padding: 3rem 1rem 2rem 1rem;
}

.public-layout .sice-home .end .crack-ux-content
{
	width: 1024px;
}

@media screen and (min-width: 768px)
{
	.public-layout .sice-home .end .crack-ux-content .crack-ux-panel
	{
		padding-left: 75px;
	}
}

@media screen and (min-width: 1024px)
{
	.public-layout .sice-home .end .crack-ux-content .crack-ux-panel
	{
		padding-left: 90px;
	}

	.public-layout .sice-home .end .crack-ux-content .crack-ux-panel:first-child
	{
		padding-left: 5px;
	}
}


.public-layout .sice-home .end .column-title
{
	font-weight: bold;
	font-size: 1.5rem;
	color: #FFFFFF;
	margin-bottom: 1rem;
	margin-top: 0.75rem;
}

@media screen and (min-width: 768px)
{
	.public-layout .sice-home .end .column-title
	{
		margin-top: 0;
	}
}

.public-layout .sice-home .end .logo
{
	font-weight: bold;
	font-size: 3rem;
	display: block;
	color: #FFFFFF;
	margin-bottom: 0.5rem;
}

.public-layout .sice-home .end .tagline
{
	font-size: 1.2rem;
	color: #9CA3AF;
}

.public-layout .sice-home .end .column-link
{
	font-size: 1.1rem;
	color: #D1D5DB;
	display: block;
	margin-bottom: 0.5rem;
}

.public-layout .sice-home .end .social
{
	display: flex;
	gap: 1rem;
	margin-top: 2rem;
}

.public-layout .sice-home .end .social .crack-ux-icon
{
	width: 24px;
	height: 24px;
}

.public-layout .sice-home .end .copyright
{
	text-align: center;
	margin-top: 2rem;
	font-size: 1rem;
	color: #9CA3AF;
}