.contact-windows
{
	width: 100%;
	padding: 0px 10px;
}

.contact-windows.active
{
	position: absolute;
	top: 0;
	z-index: 350;
}

.contact-windows .contacts-bar
{
	position: relative;
	width: 100%;
	_padding: 0px 10px;
	overflow-y: hidden;
	white-space: nowrap;
	user-select: none;
	cursor: pointer;
}

.contact-windows .contacts-bar::-webkit-scrollbar
{
	height: 0px !important;
	width: 0px !important;
}

.contact-windows .contacts-bar .bar-option
{
	background-color: var(--light-gray);
}

.contact-windows .contacts-bar .bar-option.current
{
	background-color: var(--white);
	box-shadow: 0px 0px 6px #cccccc;
}

.crm-module .close-tabs
{
    margin-left: 10px;
}

.crm-module .close-tabs:hover
{
    color: var(--red);
}

.crm-module .crm-chat-status
{
	width: 15px;
	height: 15px;
	border-radius: 50%;
}

.crm-module .crm-chat-status.mini
{
    width: 10px;
    height: 10px;
    margin-top: 7px;
    margin-left: 6px;
}

.crm-module .contact-content
{
	height: calc(100% - 36px);
	padding: 0px 0px 10px 0px;
	display: none;
	background-color: #f9f9f9;
}

.crm-module .contact-content.current
{
	display: block;
}

.crm-module .contact-content .window-icon
{
	color: var(--gray);
	cursor: pointer;
}

.crm-module .contact-content .window-icon:hover
{
	color: var(--red);
}

.crm-module > .contact-info
{
	height: 100%;
}

.crm-module > .contact-info > .crack-ux-panel
{
	height: 100%;
}

.crm-module > .contact-info .info
{
	border-right: solid 1px #efefef;
}