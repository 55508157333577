.business-layout .navigation .navigation-menu
{
	display: inline-block;
	width: calc(100% - 42px);
	height: 100vh;
	transition: width 0.3s ease-in-out;
}

@media screen and (min-width: 768px)
{
	.business-layout .navigation .navigation-menu
	{
		width: 240px;
	}
}

.business-layout .navigation .navigation-menu.hide
{
	width: 0px;
}

.business-layout .navigation .navigation-menu .module
{
	background-color: #ffffff;
	border-right: solid 1px #efefef;
	border-bottom: solid 1px #efefef;
	height: var(--header);
}

.business-layout .navigation .navigation-menu .module .crack-ux-text
{
	margin-top: 2px;
}

.business-layout .navigation .navigation-menu.mobile-menu
{
	position: absolute;
	background-color: #f9f9f9;
	overflow: hidden;
	z-index: 1000;
	left: 42px;
}

.business-layout .navigation .navigation-menu .container-menu
{
	height: calc(100% - var(--header));
}

.business-layout .navigation .navigation-menu .menu
{
	background: transparent;
	padding: 15px;
}

.business-layout .navigation .navigation-menu .menu .crack-option
{
	background: #ffffff;
	margin-bottom: 10px;
	padding: 0px;
	border-radius: 5px;
	overflow: hidden;
	white-space: nowrap;
}

.business-layout .navigation .navigation-menu .menu .crack-option:last-child
{
	margin-bottom: 0px;
}

.business-layout .navigation .navigation-menu .menu .crack-option .crack-ux-text
{
	line-height: 1em;
	padding: 12px;
}