.crm-campaigns .funnel
{
	max-width: 480px;
	margin: 0 auto;
	padding: 25px;
}

.crm-campaigns .funnel .stage,
.crm-campaigns .exclusion-point .stage
{
	padding: 15px 10px;
}

.crm-campaigns .funnel .stage .crack-ux-text,
.crm-campaigns .exclusion-point .stage .crack-ux-text
{
	line-height: 2rem;
}

.crm-campaigns .exclusion-point .stage
{
	margin-bottom: 10px;
}