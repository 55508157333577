.home-module .modules .description .crack-ux-text
{
	margin-bottom: 15px;
}

.home-module .modules .description .crack-ux-text:last-child
{
	margin-bottom: 0px;
}

.home-module .modules .thumbnails .thumbnail
{
	margin-right: 15px;
}

.home-module .modules .thumbnails .thumbnail:last-child
{
	margin-right: 0px;
}

@media screen and (min-width: 768px)
{
	.home-module .modules .plans-text
	{
		margin: 25px 10%;
	}
}

@media screen and (min-width: 1024px)
{
	.home-module .modules .plans-text
	{
		margin: 25px 15%;
	}
}