/****************************
*           scroll	        *
****************************/
@media screen and (min-width: 1024px)
{
	*
	{
		scrollbar-color: var(--scrollColor) transparent !important;
	}
	
	*::-webkit-scrollbar
	{
		width: 5px !important;
		height: 5px !important;
	}
	
	*::-webkit-scrollbar-track
	{
		background: var(--scrollBGColor) !important;
	}
	
	*::-webkit-scrollbar-thumb
	{
		background: var(--scrollColor) !important;
	}
	
	textarea::-webkit-scrollbar
	{
		width: 0px !important;
	}
	
	.crack-ux-tabs .crack-tabs-header .crack-ux-section::-webkit-scrollbar
	{
		width: 0px !important;
		height: 0px !important;
	}
}