:root
{
	--blue: #1889d4;

    --primary: #00aba5;
	--secondary: #add546;

    --header: 46px;
    --footer: 61px;
}

.bg-primary
{
    background: var(--primary) !important;
}

.primary
{
    color: var(--primary) !important;
}

.bg-secondary
{
    background: var(--secondary) !important;
}

.secondary
{
    color: var(--secondary) !important;
}

.masque
{
	font-family: 'Masque', Helvetica, sans-serif;
}

.logo
{
    font-family: 'Masque', Helvetica, sans-serif;
    background: linear-gradient(135deg, var(--primary), var(--secondary));
    background-clip: text;
    color: transparent;
}

@font-face
{
	font-family: 'Masque';
	src: url('./externals/fonts/Masque.woff2') format('woff2'),
		url('./externals/fonts/Masque.woff') format('woff'),
		url('./externals/fonts/Masque.ttf') format('truetype');
}