/* Init */
.business-page .home .init
{
	margin-top: 67px;
	height: 560px;
}

.business-page .home .init .cover-page
{
	width: 100%;
	height: 480px;
	object-fit: cover;
}

.business-page .home .init .business-info
{
	position: absolute;
	width: calc(100% - 200px);
	left: 100px;
	top: 380px;
	display: flex;
	align-items: flex-end
}

.business-page .home .init .business-info .logo
{
	border: solid 5px #ffffff;
	width: 200px;
	height: 200px;
	font-size: 5em;
}

.business-page .home .init .business-info .business-name
{
	display: inline-block;
	margin-left: 24px;
	margin-bottom: 24px;
	font-size: 52px;
}

/* About Us */
.business-page .home .about-us
{
	padding: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.business-page .home .about-us .message
{
	flex: 1;
}

.business-page .home .about-us .message .title
{
	margin: 0px;
}

.business-page .home .about-us .message .crack-ux-footer .crack-ux-button
{
	border-radius: 10px;
	padding: 10px 35px;
}

.business-page .home .about-us .image
{
	height: 420px;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* products */
.business-page .home .products > .crack-ux-footer
{
	margin-top: 35px;
}

/* Services */
.business-page .home .services > .crack-ux-footer
{
	margin-top: 35px;
}

/* Contact Us */
.business-page .home .contact-us .address .crack-ux-card
{
	border-radius: 10px;
	padding: 10px;
}

.business-page .home .contact-us .address .crack-ux-card .crack-ux-header .crack-ux-text
{
	font-weight: bold;
	font-size: 14px;
}

.business-page .home .contact-us .address .crack-ux-card .crack-ux-body
{
	padding-top: 0px;
}

.business-page .home .contact-us .phones
{
	margin-top: 20px;
}

.business-page .home .contact-us .phones .phone
{
	border-radius: 10px;
	box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
}

.business-page .home .contact-us .phones .phone .crack-ux-text
{
	font-size: 12px;
}

.business-page .home .contact-us .phones .phone .crack-ux-icon
{
	font-size: 14px;
	margin-right: 5px;
}

.business-page .home .contact-us .phones .phone .crack-ux-text:first-child
{
	font-weight: bold;
}