.business-layout .navigation .navigation-modules
{
	display: inline-block;
	height: 100vh;
}

.business-layout .navigation .navigation-modules .menu-icon .icon-menu
{
	background-color: #ffffff;
	border-right: solid 1px #efefef;
	border-bottom: solid 1px #efefef;
	color: #333333;
	display: block;
	padding: 16px 12px;
	width: 42px;
	height: var(--header);
}

.business-layout .navigation .navigation-modules .menu-icon.mobile-menu-icon
{
	position: absolute;
	z-index: 999;
	background-color: #f9f9f9;
}

.business-layout .navigation .navigation-modules .modules
{
	height: calc(100% - var(--header));
	overflow-y: auto;

	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.business-layout .navigation .navigation-modules .modules::-webkit-scrollbar
{
	display: none;
}

.business-layout .navigation .navigation-modules .modules.mobile-modules
{
	position: absolute;
	z-index: 999;
	background-color: #f9f9f9;
	top: var(--header);
}

.business-layout .navigation .navigation-modules .modules.mobile-modules.hide
{
	-webkit-animation:hide-modules 0.7s forwards;
	animation:hide-modules 0.7s forwards;
}

@-webkit-keyframes hide-modules
{
	0% {opacity: 1;}
	100% { opacity: 0.4; display: none;}
}

@keyframes hide-modules
{
	0% {opacity: 1;}
	100% { opacity: 0.4; height: 0; width: 0;}
}

.business-layout .navigation .modules .module
{
	display: block;
	border: solid 1px var(--borderInput);
	border-left: none;
	background-color: #ffffff;
	color: #333333;
	width: 42px;
	margin-top: 10px;
	padding: 12px;
	border-radius: 0px 5px 5px 0px;
}

.business-layout .navigation .modules .module.current
{
	border: none;
	background-color: var(--blue);
	color: #ffffff;
}