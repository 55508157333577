.permissions .permission-item
{
	width: auto;
}

/* Indentation for different levels */
.permissions .permission-item.level-0
{
	border: solid 1px #efefef;
	margin-bottom: 15px;
	padding: 15px;
	border-radius: 10px;
}

.permissions .permission-item.level-0:last-child
{
	margin-bottom: 0px;
}

.permissions .permission-item.level-1
{
	width: calc(100% - 15px);
	margin-left: 15px;
}

.permissions .permission-item.level-2
{
	width: calc(100% - 30px);
	margin-left: 30px;
}

/* Optional: Different font sizes or styles */
.permissions .permission-item.level-0 .title
{
	font-size: 1.2em;
}

.permissions .permission-item.level-1 .title
{
	font-size: 1em;
}