@media screen and (min-width: 768px)
{
	.contact-info > .crack-ux-header > .back-arrow
	{
		display: none;
	}
}

.contact-info > .crack-ux-body
{
	height: calc(100% - 61px - var(--footer)); /* 61px is the height of the header */
	overflow-y: auto;
}

@media screen and (min-width: 768px)
{
	.contact-info > .crack-ux-body
	{
		height: calc(100% - var(--header) - var(--footer));
	}
}

.contact-info > .crack-ux-body > .crack-ux-section
{
	padding: 15px;
}

.contact-info > .crack-ux-body .actions .crack-ux-icon
{
	cursor: pointer;
	padding: 10px;
	margin-left: 10px;
	border-radius: 50%;
}

.contact-info > .crack-ux-body .info
{
	line-height: 2em;
}

.contact-info > .crack-ux-body .info .label
{
	font-weight: bold;
	margin-right: 5px;
}

.contact-info > .crack-ux-body .info .value
{
	line-height: 2em;
}

.contact-info > .crack-ux-body .company
{
	background-color: #f6f6f6;
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 5px;
}

.contact-info > .crack-ux-body .company:last-child
{
	margin-bottom: 0px;
}