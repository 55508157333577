.location .caption
{
	display: block;
	margin-bottom: 10px;
	font-weight: bold;
}

.location .map-container
{
	height: 360px;
}

@media screen and (min-width: 768px)
{
	.location .map-container
	{
		height: 420px;
	}
}

@media screen and (min-width: 1024px)
{
	.location .map-container
	{
		height: 780px;
	}
}

.location .form-container
{
	margin-top: 30px;
}

@media screen and (min-width: 1024px)
{
	.location .form-container
	{
		margin-top: 0px;
	}
}

.location .form-container .places
{
	font-family: var(--fontFamily);
	margin-bottom: 20px;
}