.products-grid .product-card .limited
{
	border: solid 1px var(--red);
}

.products-grid .product-card .crack-ux-body .amount
{
	padding: 2px 5px;
	top: 0px;
	left: 0px;
	z-index: 10;
}

.products-grid .product-card .crack-ux-body .cancel
{
	padding: 2px 0px;
	top: 0px;
	right: 0px;
	z-index: 10;
}

.products-grid .product-card .crack-ux-footer
{
	width: 105px;
	padding: 7px 10px;
	white-space: nowrap;
	overflow: hidden;
}