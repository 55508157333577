.home-web-page .preview .crack-ux-header .crack-ux-icon
{
	background-color: var(--button-bg);	
	padding: 5px;
	margin-left: 5px;
	cursor: pointer;
}

.home-web-page .preview .crack-ux-header .crack-ux-icon.active
{
	background-color: var(--blue);
	color: white;
}