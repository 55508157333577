.personal-layout .business-page .business-option .crack-ux-box
{
	vertical-align: middle;
	padding: 11.5px 5px;
}

@media screen and (min-width: 768px)
{
	.personal-layout .business-page .business-option .crack-ux-box
	{
		padding: 8px 5px;
	}
}

@media screen and (min-width: 1024px)
{
	.personal-layout .business-page .business-option .crack-ux-box
	{
		padding: 5px;
	}
}

.personal-layout .business-page .business-option.new-business.active
{
	background: var(--blue);
	cursor: pointer;
	color: #ffffff;
}

.personal-layout .business-page .business-option.new-business.disabled
{
	background: #cccccc;
	color: #444444;
}

.personal-layout .business-page .business-option.new-business .crack-ux-icon
{
	font-size: 2.72em;
}

@media screen and (min-width: 768px)
{
	.personal-layout .business-page .business-option.new-business .crack-ux-icon
	{
		font-size: 3.65em;
	}
}