.crm-chats .contact
{
	padding: 15px;
	border-bottom: solid 1px #efefef;
}

.crm-chats .contact .picture
{
	margin-top: 2px;
	margin-right: 15px;
}

.crm-chats .contact .unread
{
	padding: 5px 7px;
	border-radius: 50%;
}

.crm-chats .crm-chat > .crack-ux-header > .info
{
	margin-bottom: 15px;
}

@media screen and (min-width: 768px)
{
	.crm-chats .crm-chat > .crack-ux-header > .info
	{
		display: none;
	}
}

.crm-chats .crm-chat > .crack-ux-header > .info .crack-ux-avatar
{
	margin-right: 5px;
}

.crm-chats .crm-chat > .crack-ux-header > .channels > .crack-ux-select
{
	display: inline-block;
	width: 50%;
	padding-right: 10px;
}

.crm-chats .crm-chat > .crack-ux-header > .channels > .crack-ux-select:last-child
{
	padding-right: 0px;
}

.crm-chats .crm-chat > .crack-ux-body
{
	height: calc(100% - 132px);
}

@media screen and (min-width: 768px)
{
	.crm-chats .crm-chat > .crack-ux-body
	{
		height: calc(100% - 85px);
	}
}