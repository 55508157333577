.dashboard-page .dashboard-menu .crack-option
{
    text-align: center !important;
    background: #f6f6f6;
    margin-bottom: 15px !important;
	padding: 0px;
}

.dashboard-page .dashboard-menu .crack-option .crack-ux-text
{
	line-height: 1em;
	padding: 15px;
}