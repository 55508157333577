.business-page
{
	background-color: #f9f9f9;
}

.business-page > .crack-ux-content > .crack-ux-header
{
	background-color: #f9f9f9;
	padding: 20px 0px;
	font-size: 2em;
}

/* Shopping Cart Icon */
.business-page .shopping-cart-icon
{
	cursor: pointer;
	float: right;
	margin-top: -3px;
	padding: 10px;
	color: white;
	z-index: 100;
}

/* Side Panel Shopping Cart */ 
.business-page .side-panel-shopping-cart
{
	position: fixed;
	background-color: #FFFFFF;
	width: 420px;
	height: 100vh;
	box-shadow: none;
}

.business-page.internal .side-panel-shopping-cart
{
	height: calc(100vh - (2 * var(--header)) - 20px);
	top: calc((2 * var(--header)) + 10px);
	left: 52px;
}

.business-page .side-panel-shopping-cart.hide
{
	width: 0px;
}

.business-page .side-panel-shopping-cart .shopping-cart
{
	height: 100%;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-header .crack-ux-text
{
	font-size: 1.25em;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-header .crack-ux-icon
{
	background-color: var(--button-bg);
	margin-top: -2px;
	font-size: 1.75em;
}

.business-page.internal .side-panel-shopping-cart .shopping-cart > .crack-ux-header .crack-ux-icon
{
	margin-top: -5px;
	font-size: 1.25em;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body
{
	height: calc(100% - 161px);
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .subtitle
{
	margin: 15px 0px;
	font-size: 16px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product
{
	padding: 15px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product:nth-child(odd)
{
	background-color: #F6F6F6;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .info
{
	margin-bottom: 10px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .info .crack-ux-text
{
	font-size: 14px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .picture
{
	margin-right: 15px;
	padding-top: 5px;
	width: 60px;
	height: 60px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .quantity .crack-ux-input
{
	display: inline-block;
	width: 100px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .quantity .arrows
{
	margin-top: 22px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .quantity .arrows .crack-ux-button
{
	display: block;
	margin: 0px;
	padding: 1px 5px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .quantity .arrows .crack-ux-button:last-child
{
	border-top: none;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .unit-price
{
	margin-top: 2px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .unit-price .price
{
	margin-top: 15px;
	font-size: 18px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .total
{
	margin-top: 15px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .total .crack-ux-text
{
	font-size: 14px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .total .crack-ux-text:last-child
{
	margin-top: 2px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .main-button
{
	margin-top: 35px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .product .main-button .crack-ux-button
{
	border-radius: 25px;
	padding: 7px 25px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .service
{
	padding: 15px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .service:nth-child(odd)
{
	background-color: #F6F6F6;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .service .info
{
	margin-bottom: 10px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .service .info .crack-ux-text
{
	font-size: 14px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .service .picture
{
	margin-right: 15px;
	padding-top: 5px;
	width: 60px;
	height: 60px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .service .remove-service
{
	margin-top: 12px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .service .unit-price
{
	margin-top: 2px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-body .service .unit-price .price
{
	margin-top: 15px;
	font-size: 18px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-footer .total
{
	margin-top: 5px;
	margin-bottom: 15px;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-footer .total .crack-ux-text
{
	font-size: 18px;
	font-weight: bold;
}

.business-page .side-panel-shopping-cart .shopping-cart > .crack-ux-footer .crack-ux-button
{
	border-radius: 25px;
	padding: 7px 25px;
}

/* Generic */
.business-page .title
{
	margin-top: 30px;
	line-height: 1.35;
	font-size: 20px;
}

.business-page .caption
{
	margin-top: 15px;
	font-size: 14px;
	color: #777777;
}

.business-page .description
{
	color: #444444;
	font-size: 16px;
}

.business-page .crack-ux-content
{
	height: auto;
}

.business-page .main-button
{
	border-radius: 50px;
	padding: 15px 35px;
	box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
}

.business-page .main-button > .crack-ux-text
{
	font-size: 16px;
}

.business-page .action-button
{
	border-radius: 25px;
	padding: 7px 25px;
	box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
}

.business-page .action-button + .action-button
{
	margin-top: 10px;
}

/* Features */
.business-page .features .crack-ux-card
{
	border-radius: 15px;
}

.business-page .features .crack-ux-card .crack-ux-header
{
	padding: 30px 20px 15px;
}

.business-page .features .crack-ux-card .crack-ux-header .crack-ux-text
{
	font-size: 16px;
	font-weight: bold;
}

.business-page .features .crack-ux-card .crack-ux-header .crack-ux-icon
{
	margin-right: 10px;
	font-size: 20px;
}

.business-page .features .crack-ux-card .crack-ux-body
{
	padding: 0px 28px 30px;
}

.business-page .features .crack-ux-card .crack-ux-body .crack-ux-text
{
	font-size: 14px;
	line-height: 1.2;
}

/* Product */
.business-page .product .crack-ux-card
{
	position: relative;
	border-radius: 10px;
}

.business-page .product .crack-ux-card .crack-ux-header
{
	height: 240px;
	padding: 35px;
}

.business-page .product .crack-ux-card .crack-ux-header .crack-ux-box
{
	position: absolute;
	top: 15px;
	right: 15px;
	padding: 5px 10px;
	text-align: center;
	background: #444444;
	color: #FFFFFF;
	z-index: 10;
}

.business-page .product .crack-ux-card .crack-ux-body
{
	padding: 35px 15px 25px;
}

.business-page .product .crack-ux-card .crack-ux-body .name
{
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 10px;
}

.business-page .product .crack-ux-card .crack-ux-body .price
{
	font-size: 16px;
	font-weight: bold;
}

.business-page .product .crack-ux-card .crack-ux-footer
{
	border-radius: 0px 0px 10px 10px;
	padding: 0px 20px 20px;
}

/* Product Detail Modal */
.product-detail-modal
{
	width: calc(100vw - 30px);
	height: calc(100vh - 30px);
}

.product-detail-modal .product-detail > .crack-ux-body
{
	height: calc(100% - 48px);
}

/* Product Detail */
.product-detail .crack-ux-body .product-header-background
{
	padding: 35px 15px;
}

.product-detail .crack-ux-body .product-header-background .crack-ux-text
{
	font-size: 14px;
	text-transform: uppercase;
}

.product-detail .crack-ux-body .current-picture
{
	height: 240px;
	padding: 35px;
}

.product-detail .crack-ux-body .pictures
{
	background: #F6F6F6;
	padding: 10px;
}

.product-detail .crack-ux-body .pictures .crack-ux-box
{
	width: 64px;
	height: 64px;
}

.product-detail .crack-ux-body .pictures .crack-ux-box + .crack-ux-box 
{
	margin-left: 15px;
}

.product-detail .crack-ux-body > .description
{
	padding: 15px 15px 25px;
}

.product-detail .crack-ux-body .description .crack-ux-text
{
	font-size: 14px;
}

.product-detail .crack-ux-body .features
{
	margin-bottom: 25px;
}

.product-detail .crack-ux-body .features .feature
{
	margin-bottom: 10px;
	padding: 15px;
}

.product-detail .crack-ux-body .features .feature:nth-child(odd)
{
	background-color: #F6F6F6;
}

.product-detail .crack-ux-body .features .feature .name
{
	font-weight: bold;
	font-size: 14px;
}

.product-detail .crack-ux-body .features .feature .description
{
	font-size: 14px;
}

.product-detail .crack-ux-body .features .feature:last-child
{
	margin-bottom: 0px;
}

.product-detail .amount .quantity .crack-ux-input
{
	display: inline-block;
	width: 100px;
}

.product-detail .amount .quantity .arrows
{
	margin-top: 22px;
}

.product-detail .amount .quantity .arrows .crack-ux-button
{
	display: block;
	margin: 0px;
	padding: 1px 5px;
}

.product-detail .amount .quantity .arrows .crack-ux-button:last-child
{
	border-top: none;
}

.product-detail .amount .unit-price
{
	margin-top: 2px;
}

.product-detail .amount .unit-price .price
{
	margin-top: 15px;
	font-size: 18px;
}

.product-detail .amount .total
{
	margin-top: 15px;
}

.product-detail .amount .total .crack-ux-text
{
	font-size: 14px;
}

.product-detail .amount .total .crack-ux-text:last-child
{
	margin-top: 2px;
}

.product-detail .amount .main-button
{
	margin-top: 35px;
}

.product-detail .amount .main-button .crack-ux-button
{
	border-radius: 25px;
	padding: 7px 25px;
}

/* Service */
.business-page .service .crack-ux-card
{
	border-radius: 35px;
}

.business-page .service .crack-ux-card .crack-ux-header
{
	position: relative;
	border-radius: 35px 35px 0px 0px;
	padding: 35px 15px;
	z-index: 10;
}

.business-page .service .crack-ux-card .crack-ux-header > .name
{
	font-size: 14px;
	text-transform: uppercase;
}

.business-page .service .crack-ux-card .crack-ux-header > .price
{
	margin: 5px 0px;
	font-size: 36px;
}

.business-page .service .crack-ux-card .crack-ux-header > .period
{
	font-size: 14px;
	text-transform: uppercase;
}

.business-page .service .crack-ux-card .header-arrow
{
	position: sticky;
	width: 100px;
	height: 100px;
	margin-top: -50px;
	left: 50%;
	transform: translateX(-50%);
	clip-path: polygon(50% 25%, 100% 50%, 50% 75%, 0% 50%);
}

.business-page .service .crack-ux-card .crack-ux-body
{
	padding: 60px 0px 0px;
}

.business-page .service .crack-ux-card .crack-ux-body .feature
{
	padding: 15px 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.business-page .service .crack-ux-card .crack-ux-body .feature:nth-child(odd)
{
	background-color: #F6F6F6;
}

.business-page .service .crack-ux-card .crack-ux-body .feature .crack-ux-text
{
	flex: 1;
	margin-right: 20px;
	font-size: 14px;
}

.business-page .service .crack-ux-card .crack-ux-body .feature .checked
{
	font-size: 20px;
}

.business-page .service .crack-ux-card .crack-ux-body .feature .checked.none
{
	color: #FE381F;
}

.business-page .service .crack-ux-card .crack-ux-body .feature .checked.selected
{
	color: #7FCf6D;
}

.business-page .service .crack-ux-card .crack-ux-footer
{
	padding: 40px 15px 30px;
}

/* Service Detail Modal */
.service-detail-modal
{
	width: calc(100vw - 30px);
	height: calc(100vh - 30px);
}

.service-detail-modal .service-detail > .crack-ux-body
{
	height: calc(100% - 48px);
}

/* Service Detail */
.service-detail > .crack-ux-body > .crack-ux-header
{
	position: relative;
	padding: 35px 15px;
	z-index: 10;
}

.service-detail > .crack-ux-body > .crack-ux-header > .name
{
	font-size: 14px;
	text-transform: uppercase;
}

.service-detail > .crack-ux-body > .crack-ux-header > .price
{
	margin: 5px 0px;
	font-size: 36px;
}

.service-detail > .crack-ux-body > .crack-ux-header > .period
{
	font-size: 14px;
	text-transform: uppercase;
}

.service-detail > .crack-ux-body > .header-arrow
{
	position: sticky;
	width: 100px;
	height: 100px;
	margin-top: -50px;
	left: 50%;
	transform: translateX(-50%);
	clip-path: polygon(50% 25%, 100% 50%, 50% 75%, 0% 50%);
}

.service-detail > .crack-ux-body > .picture
{
	height: 240px;
	margin-top: -20px;
	padding: 35px;
}

.service-detail > .crack-ux-body > .crack-ux-body
{
	padding: 0px;
}

.service-detail > .crack-ux-body > .crack-ux-body .description
{
	padding: 15px;
}

.service-detail > .crack-ux-body > .crack-ux-body .description > .crack-ux-text
{
	font-size: 14px;
}

.service-detail > .crack-ux-body > .crack-ux-body .features
{
	margin-top: 25px;
}

.service-detail > .crack-ux-body > .crack-ux-body .features .feature
{
	padding: 15px 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.service-detail > .crack-ux-body > .crack-ux-body .features .feature:nth-child(odd)
{
	background-color: #F6F6F6;
}

.service-detail > .crack-ux-body > .crack-ux-body .features .feature .crack-ux-text
{
	flex: 1;
	margin-right: 20px;
	font-size: 14px;
}

.service-detail > .crack-ux-body > .crack-ux-body .features .feature .checked
{
	font-size: 20px;
}

.service-detail > .crack-ux-body > .crack-ux-body .features .feature .checked.none
{
	color: #FE381F;
}

.service-detail > .crack-ux-body > .crack-ux-body .features .feature .checked.selected
{
	color: #7FCf6D;
}

.service-detail > .crack-ux-body > .crack-ux-footer
{
	border: solid 1px black;
	margin-top: 30px;
}

.service-detail > .crack-ux-body > .crack-ux-footer .main-button
{
	border-radius: 25px;
	padding: 7px 25px;
}

/* Review */
.business-page .review .crack-ux-card
{
	border-radius: 15px;
}

.business-page .review .crack-ux-header
{
	margin-top: 10px;
}

.business-page .review .crack-ux-header .crack-ux-text
{
	margin-top: 8px;
	margin-left: 10px;
	font-size: 16px;
}

.business-page .review .crack-ux-body
{
	padding: 0px 15px;
}

.business-page .review .qualification
{
	margin-bottom: 10px;
	color: #FFD700;
}

.business-page .review .qualification .empty
{
	color: #B0B0B0;
}