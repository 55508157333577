.personal-layout .personal-header .version
{
	font-size: 0.8rem;
	margin-left: 0.5rem;
	color: #dddddd
}

.personal-layout .personal-header .crack-ux-avatar
{
	margin-top: -9px;
}

.personal-layout .content
{
	height: calc(100vh - var(--header) - 40px); /* First subtract the header height, then subtract the menu height */
}

.personal-layout .menu
{
	width: 100%;
	border-top: solid 1px #dddddd;
}

@media screen and (min-width: 768px)
{
	.personal-layout .menu
	{
		border-top: none;
		position:static;
		height: 100%;
	}
}

.personal-layout .menu > .options
{
	width: 25%;
	padding: 10px;
	color: #666666;
	border-right: solid 1px #dddddd;
}

.personal-layout .menu > .options:last-child
{
	border-right: none;
}

.personal-layout .menu .crack-ux-list .crack-option
{
    background: #f6f6f6;
    margin-bottom: 15px;
	padding: 0px;
}

.personal-layout .menu .crack-ux-list .crack-option .crack-ux-text
{
	line-height: 1em;
	padding: 15px;
}