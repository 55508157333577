.public-layout .login .full-center
{
	width: calc(100% - 30px);
}

@media screen and (min-width: 768px)
{
	.public-layout .login .full-center
	{
		width: auto;
	}
}

.public-layout .login .crack-ux-form .crack-ux-input .input
{
	background-color: #ffffff;
}

.public-layout .login .crack-ux-footer .version
{
	font-size: 1.5rem;
	color: var(--purple);
	letter-spacing: 0.15rem;
}