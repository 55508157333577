.public-layout .create-account .content
{
	width: calc(100% - 30px);
}

@media screen and (min-width: 768px)
{
	.public-layout .create-account .content
	{
		width: 600px;
		position: absolute !important;
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%) !important;
	}
}

.public-layout .create-account .crack-ux-form .crack-ux-input .input,
.public-layout .create-account .crack-ux-form .crack-ux-select .select
{
	background-color: #ffffff;
}